<template>
  <div class="wrap-list-platform">
    <CRow>
      <CCol class="col--padding">
        <CCard>
          <CCardHeader>
            <h5>{{$t("LOGIN_HISTORY_PAGE_TITLE_HISTORY")}}</h5>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12">
                <!-- <Tables :items="items" :fields="fields" hover striped bordered /> -->
                <CDataTable
                  class="mb-0"
                  hover
                  outlined
                  striped
                  bordered
                  :items="items"
                  :fields="fields"
                  head-color="light"
                  no-sorting
                >
                  <td slot="order" slot-scope="{item, index}">{{index + 1}}</td>
                  <td
                    slot="updatedAt"
                    slot-scope="{item}"
                  >{{item.updatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
                </CDataTable>
                <div>
                  <CPagination
                    :activePage.sync="activePage"
                    :pages="pages"
                    align="end"
                    @update:activePage="pushPage"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Tables from "@/components/ChildPool/Tables";
import endpoints from "@/constants/endpoints";
export default {
  components: {
    Tables
  },
  data() {
    return {
      fields: [
        {
          key: "order",
          label: "#"
        },
        {
          key: "updatedAt",
          label: this.$t("LOGIN_HISTORY_PAGE_LABEL_LOGIN_AT")
        },
        {
          key: "client_ip",
          label: this.$t("LOGIN_HISTORY_PAGE_LABEL_IP"),
          _style: "width:15%"
        },
        {
          key: "user_agent",
          label: this.$t("LOGIN_HISTORY_PAGE_LABEL_CLIENT"),
          _style: "width:60%"
        }
      ],
      items: [],
      activePage: 1,
      limit: 5,
      pages: 0
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    }
  },
  async mounted() {
    let data = await this.getHistory();
    if (!data) {
      return;
    }
    console.log(data);

    this.items = data.items.slice();
    this.pages = Math.ceil(parseInt(data.total) / this.limit);
  },
  methods: {
    async getHistory() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        };
        let res = await this.$http.get(endpoints.getLoginHistory, {
          params: params
        });

        return res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async pushPage() {
      let items = await this.getHistory();
      this.items = items.items.slice();
    }
  }
};
</script>
<style lang="scss">
.wrap-list-platform>.row{
  margin: 0;
  .col--padding{
    padding: 0 24px;
  }
}
</style>